import classNames from 'classnames';
import { LegacyRef, MouseEvent, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as CheckIco } from '../assets/check.svg';
import styles from './button.module.scss';
import Ico, { Circle } from './ico';
import Box, { Colors } from './utils';

export type ButtonAppereance = 'primary' | 'warn' | 'danger' | 'icon' | 'secondary';
export type ButtonV2Props = PropsWithChildren<{
  apperance?: ButtonAppereance;
  disabled?: boolean;
  href?: string;
  size?: 'l' | 'm' | 's' | 'xs';
  className?: string;
  ralign?: boolean;
  tooltip?: string;
  tabindex?: number;
  ref?: LegacyRef<HTMLButtonElement> | undefined;
  type?: 'submit' | 'reset' | 'button' | undefined;
  onClick?: (event: MouseEvent) => void;
  autoFocus?: boolean;
  submitSelector?: boolean;
  dataCy?: string;
  block?: boolean;
}>;
export default function ButtonV2(props: ButtonV2Props) {
  const kind = props.apperance || 'primary';
  const block = props.block ? 'block' : '';
  const size = `size_${props.size || 'm'}`;
  const alignCls = props.ralign ? styles.allignRight : '';
  let dataAttributes: Record<string, any> = {};
  if (props.autoFocus) {
    dataAttributes['data-auto-focus'] = true;
  }
  if (props.submitSelector) {
    dataAttributes['data-submit-selector'] = true;
  }

  if (props.href) {
    return (
      <Link
        {...dataAttributes}
        title={props.tooltip}
        to={props.href}
        className={classNames(styles.root, props.className, styles[size], styles[block], styles[kind], alignCls, props.disabled === true ? styles.disabled : '')}
        onClick={props.disabled ? (e) => e.preventDefault() : props.onClick}
      >
        {props.children}
      </Link>
    );
  } else {
    return (
      <button
        {...dataAttributes}
        ref={props.ref}
        title={props.tooltip}
        tabIndex={props.tabindex}
        type={props.type || 'button'}
        disabled={props.disabled}
        className={classNames(styles.root, styles[size], styles[block], props.className, styles[kind], alignCls, props.disabled === true ? styles.disabled : '')}
        onClick={props.onClick}
        data-cy={props.dataCy}
      >
        {props.children}
      </button>
    );
  }
}

export function ButtonV2Primary(props: Omit<ButtonV2Props, 'apperance'>) {
  return <ButtonV2 {...props} apperance={'primary'}></ButtonV2>;
}

export function ButtonSecondary(
  props: PropsWithChildren<{
    autoFocus?: boolean;
    submitSelector?: boolean;
    disabled?: boolean;
    href?: string;
    className?: string;
    tooltip?: string;
    ralign?: boolean;
    type?: 'submit' | 'reset' | 'button' | undefined;
    onClick?: () => void;
    dataCy?: string;
  }>
) {
  return <ButtonV2 {...props} apperance={'secondary'}></ButtonV2>;
}

// export function ButtonAccent(
//   props: PropsWithChildren<{
//     autoFocus?: boolean;
//     submitSelector?: boolean;
//     disabled?: boolean;
//     href?: string;
//     className?: string;
//     tooltip?: string;
//     ralign?: boolean;
//     type?: 'submit' | 'reset' | 'button' | undefined;
//     onClick?: () => void;
//     dataCy?: string;
//   }>
// ) {
//   return <ButtonV2 {...props} apperance={'accent'}></ButtonV2>;
// }

export interface ButtonSegmentsProps {
  className?: string;
  size?: 'l' | 'm' | 's' | 'xs';
}
export function ButtonSegments(props: PropsWithChildren<ButtonSegmentsProps>) {
  const size = `size_${props.size || 'm'}`;
  return (
    <Box kind={'hflex'} className={classNames(styles.buttonSegments, props.className, styles[size])}>
      {props.children}
    </Box>
  );
}

export type ButtonSegmentProps = PropsWithChildren<{
  disabled?: boolean;
  href?: string;
  size?: 'l' | 'm' | 's' | 'xs';
  className?: string;
  ralign?: boolean;
  tooltip?: string;
  tabindex?: number;
  ref?: LegacyRef<HTMLButtonElement> | undefined;
  type?: 'submit' | 'reset' | 'button' | undefined;
  onClick?: (event: MouseEvent) => void;
  autoFocus?: boolean;
  submitSelector?: boolean;
  toggled?: boolean;
  dataCy?: string;
  block?: boolean;
}>;
export function ButtonSegment(props: PropsWithChildren<ButtonSegmentProps>) {
  const allClasses = classNames(props.className, styles.segment, props.toggled ? styles.toggled : '');
  return (
    <ButtonV2 {...props} className={allClasses}>
      {props.children}
    </ButtonV2>
  );
}

export interface ButtonTogglesProps {
  size?: 'l' | 'm' | 's' | 'xs';
  className?: string;
}
export function ButtonToggles(props: PropsWithChildren<ButtonTogglesProps>) {
  const size = `size_${props.size || 'm'}`;
  return (
    <Box kind={'hflex'} gap={'m'} className={classNames(styles.buttonToggles, props.className, styles[size])}>
      {props.children}
    </Box>
  );
}

export type ButtonToggleProps = PropsWithChildren<{
  disabled?: boolean;
  href?: string;
  size?: 'l' | 'm' | 's' | 'xs';
  className?: string;
  ralign?: boolean;
  tooltip?: string;
  tabindex?: number;
  ref?: LegacyRef<HTMLButtonElement> | undefined;
  type?: 'submit' | 'reset' | 'button' | undefined;
  onClick?: (event: MouseEvent) => void;
  autoFocus?: boolean;
  submitSelector?: boolean;
  toggled?: boolean;
  dataCy?: string;
  block?: boolean;
  circle?: {
    color: Colors;
  };
}>;
export function ButtonToggle(props: PropsWithChildren<ButtonToggleProps>) {
  const allClasses = classNames(props.className, styles.buttonToggle, props.toggled ? styles.toggled : '');
  return (
    <ButtonV2 {...props} className={allClasses}>
      {props.circle && <Circle color={props.circle.color} borderColor={props.toggled ? 'brand-neutral-000' : undefined} />}
      {props.children}
      {props.toggled && <Ico size='12px' fill='primary-neutral-100' file={<CheckIco />} />}
    </ButtonV2>
  );
}

// export interface IcoBtnProps{
//   className?: string;
// }

// export default function IcoBtn(props: IcoBtnProps) {

//   return (
//      <Butt className={classNames(styles.icoBtn, props.className)}>

//      </Butt>
//   );
// }
