import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import { ReactComponent as ChevronIco } from '../assets/chevron-left.svg';
import styles from './accordion.module.scss';
import ButtonV2 from './button';
import Box from './utils';

export interface AccordionProps {
  className?: string;
  collapsed?: boolean;
}

export default function Accordion(props: PropsWithChildren<AccordionProps>) {
  return (
    <Box kind='vgrid' className={classNames(styles.root, props.className, props.collapsed ? styles.collapsed : '')}>
      {props.children}
    </Box>
  );
}

export interface AccordionHeadProps {
  className?: string;
}
export function AccordionHead(props: PropsWithChildren<AccordionHeadProps>) {
  return (
    <Box kind='hflex' gap='s' className={classNames(props.className, styles.header)}>
      {props.children}
    </Box>
  );
}

export interface AccordionContentProps {
  className?: string;
}
export function AccordionContent(props: PropsWithChildren<AccordionContentProps>) {
  return (
    <Box kind='vgrid' className={classNames(props.className, styles.content)}>
      {props.children}
    </Box>
  );
}

export type AccordionExpandBtnProps = {
  className?: string;
  onClick?: () => void;
};
export function AccordionExpandBtn(props: AccordionExpandBtnProps) {
  return (
    <ButtonV2 onClick={props.onClick} className={classNames(styles.expandBtn, props.className)} apperance='icon'>
      <ChevronIco />
    </ButtonV2>
  );
}
