import { ReactComponent as DividerIco } from '../assets/divider.svg';
import { ReactComponent as ExternalHardwareIco } from '../assets/exclamation.svg';
import { ReactComponent as PublicStationsIco } from '../assets/public-stations.svg';
import Box from '../components-v2/utils';
import useBetterTranslate from '../utils/translation-utils';
import styles from './icon-explanation-section.module.scss';

export function IconExplanationItem(props: { icon?: any; explanationText?: string }) {
  return (
    <div className={styles.content}>
      <span className={styles.icons}>
        <span className={styles.icon}>{props.icon}</span>
        <span className={styles.divider}>
          <DividerIco />
        </span>
      </span>
      <span className={styles.explanation}>{props.explanationText}</span>
    </div>
  );
}

function ExternalHardwareExplanation() {
  const { _t } = useBetterTranslate('icon-explanation-section');
  return (
    <IconExplanationItem
      icon={<ExternalHardwareIco />}
      explanationText={_t(
        'Externe Hardware die nicht von TotalEnergies vertrieben wird mit eingeschränktem Funktionsumfang! Wenden Sie sich für technischen Support direkt an den Hersteller der Hardware.'
      )}
    />
  );
}

function PublicStationsExplanation() {
  const { _t } = useBetterTranslate('icon-explanation-section');
  return (
    <IconExplanationItem
      icon={<PublicStationsIco />}
      explanationText={_t('Diese Station ist öffentlich gestellt und kann mit Karten von Mobilitätsanbietern im Roaming-Netzwerk genutzt werden.')}
    />
  );
}

export default function IconExplanationSection(props: { showExternalHardware?: boolean; showPublicStations?: boolean }) {
  const { _t } = useBetterTranslate('icon-explanation-section');

  return (
    <Box kind={'vflex'} gap='m'>
      <Box fw='700' className={styles.title}>
        {_t('Bitte beachten Sie diese Spezifikationen der Hardware:')}
      </Box>

      {props.showExternalHardware && <ExternalHardwareExplanation />}
      {props.showPublicStations && <PublicStationsExplanation />}
    </Box>
  );
}
