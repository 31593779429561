import classNames from 'classnames';
import * as _ from 'lodash';
import { PropsWithChildren, ReactNode, useLayoutEffect, useMemo, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import shortUUID from '../../services/short-id';
import { percentageSave } from '../../utils/math';

import { TimeGroupResolution } from '../../services/api-client/csp-api';
import { ExtractFromUnion } from '../../ts-type-utils';
import { formateByResolution } from '../../utils/date';
import { Colors, coloToVar } from '../utils';
import styles from './stacked-bar-chart.module.scss';

export type Apperance = ExtractFromUnion<
  Colors,
  | 'green-kelly-green'
  | 'brown-gold-metallic'
  | 'functional-error-full'
  | 'primary-neutral-090'
  | 'secondary-teal'
  | 'blue-blue-jeans'
  | 'red-light-salmon'
  | 'functional-disabled-200'
  | 'dataviz-green-light-green'
  | 'dataviz-blue-pale-cyan'
  | 'dataviz-blue-medium-persian'
  | 'atenso-blue'
>;
export interface StackedBarChartTooltipData {
  idx: number;
  xKey: string;
  points: {
    name: string;
    value: number;
  }[];
}

export interface StackedBarChartSeries {
  name: string;
  data: { y: number; xKey: string }[];
  apperance: Apperance;
}

function StackedBar(props: { yMax: number; bars: { value: number; name: string; apperance: Apperance }[] }) {
  const elRef = useRef<HTMLDivElement>(null);
  const [barsVm, setBarsVm] = useState<{ apperance: Apperance; height: string }[]>([]);

  useLayoutEffect(() => {
    if (elRef?.current) {
      const el = elRef?.current;
      const totalHeight = el.parentElement!.clientHeight;
      const vms = props.bars.map((bar) => {
        const per = percentageSave(bar.value, props.yMax);
        const h = `${((totalHeight * per) / 100).toFixed(2)}px`;
        // const h = `${per.toFixed(2)}%`;
        return { apperance: bar.apperance, height: h };
      });
      setBarsVm(vms);
    }
  }, [elRef, props.bars, props.yMax]);

  // const yValues = props.bars;
  // //  _.orderBy(step.yValues, v => v.value, 'desc');
  // const bars = yValues.map((bar, i2) => {
  //   let size = '0px';
  //   const per = percentage(bar.value, props.yMax);
  //   return <div className={styles.bar} key={i2} style={{backgroundColor: bar.color, height: `${per.toFixed(2)}%`}} ></div>
  // });

  return (
    <div ref={elRef} className={styles.barsInner}>
      {barsVm.map((b, i) => {
        return <div className={classNames(styles.bar, styles[b.apperance])} key={i} style={{ height: b.height, backgroundColor: coloToVar(b.apperance) }}></div>;
      })}
    </div>
  );
}

// function StackedBar(props: {yMax: number, bars: {value: number, name: string, color: string}[]}){

//   return (
//     <div ref={elRef} className={styles.barsInner}>
//       {barsVm.map((b, i) => {
//         return <div className={styles.bar} key={i} style={{backgroundColor: b.color, height: b.height}} ></div>
//       })}
//     </div>
//   );
// }

export default function StackedBarChart(
  props: PropsWithChildren<{
    className?: string;
    items: StackedBarChartSeries[];
    formatYLbl?: (val: number) => string;
    yLblClass?: string;
    noXlbls?: boolean;
    onTooltip?: (arg: StackedBarChartTooltipData) => ReactNode;
  }>
) {
  const tooltipId = useMemo(() => {
    return shortUUID();
  }, []);
  const allRecords = props.items.flatMap((series) => series.data);

  const xPoints =
    _.maxBy(props.items, (series) => {
      return series.data.length;
    })?.data.length || 0;

  const colSums = Array.from(Array(xPoints).keys()).map((idx) => {
    const sum = _.sumBy(props.items, (series) => (series.data.length > idx ? series.data[idx].y : 0));
    return sum;
  });

  let yMax = _.max(colSums) || 0;
  const yHalf = yMax / 2;

  const yMaxTxt = props.formatYLbl ? props.formatYLbl(yMax) : yMax.toFixed(0);
  const yHalfTxt = props.formatYLbl ? props.formatYLbl(yHalf) : yHalf.toFixed(0);

  const noXLbls = props.noXlbls === true;

  const { items } = props;
  const values = useMemo(() => {
    const result = Array.from(Array(xPoints).keys()).map((idx) => {
      let xKey = '';
      const points = items.map((series) => {
        let val = 0;
        if (series.data.length > idx) {
          val = series.data[idx].y;
        }
        xKey = series.data[idx].xKey;
        return { name: series.name, value: val, apperance: series.apperance };
      });
      return { idx: idx, points: points, xKey: xKey };
    });
    return result;
  }, [items, xPoints]);

  const { onTooltip } = props;
  const toolTipContent = useMemo(() => {
    return (data: string) => {
      // It might make sence to upgrade react-tooltip to V5. This might make the rebuild obsolete.
      // https://react-tooltip.com/docs/upgrade-guide/changelog-v4-v5
      // https://jira.totalenergies.com/browse/TECS-1109
      //
      // update 8.8.24 (vbr): I have no idea why this was here
      // the ticket above is mentioning to update the dep but does not describes why a rebuild is needed
      // this function call let the app crash on prod and makes the dev tools basically usesless during development
      // I will remove this... if this is needed pls get in touch w. me.
      // ReactTooltip.rebuild();

      if (!onTooltip) return null;

      const idx = Number.parseInt(data);
      if (!Number.isFinite(idx)) return null;

      const arg = values[idx];
      const tooltip = onTooltip(arg);
      return tooltip;
    };
  }, [onTooltip, values]);

  if (allRecords.length <= 0) return <div></div>; //no data

  return (
    <div className={classNames(styles.root, props.className, { [styles.noXAxis]: noXLbls })}>
      <div className={classNames(styles.yLbl, props.yLblClass)}>
        <div>{yMaxTxt}</div>
        <div>{yHalfTxt}</div>
        <div>0</div>
      </div>

      <ReactTooltip id={tooltipId} getContent={toolTipContent} />

      <div className={classNames(styles.barArea)}>
        {values.map((record) => {
          return (
            <div data-for={tooltipId} data-tip={record.idx} key={record.idx} className={classNames(styles.barGroup)}>
              <div className={classNames(styles.bars)}>
                <StackedBar bars={record.points} yMax={yMax} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export function chartTimePeriodLabel(resolution: TimeGroupResolution, entries: { date: string }[], languageCode: string) {
  let txt = '';
  if (resolution && entries && entries.length > 1) {
    txt = `${formateByResolution(entries[0].date, languageCode, resolution)} - ${formateByResolution(entries[entries.length - 1].date, languageCode, resolution)}`;
  } else if (resolution && entries && entries.length === 1) {
    txt = formateByResolution(entries[0].date, languageCode, resolution);
  }

  return txt;
}
