import classNames from 'classnames';
import { PropsWithChildren, ReactNode } from 'react';
import { ReactComponent as ErrorIco } from '../assets/error.svg';
import { ReactComponent as NotFoundIco } from '../assets/not-found.svg';
import { ReactComponent as SpinnerIco } from '../assets/spinner2.svg';
import { ReactComponent as SuccessIco } from '../assets/success.svg';
import { ReactComponent as UserLockIco } from '../assets/user-lock.svg';
import { ApiError } from '../services/api-client/csp-api';
import ifTrue from '../utils/class-name';
import useBetterTranslate from '../utils/translation-utils';
import styles from './page-layout.module.scss';

export interface PageLayoutProps {
  className?: string;
}
export function PageLayout(props: PropsWithChildren<PageLayoutProps>) {
  return (
    <section className={classNames(styles.pageLayout, props.className)}>
      <div className={styles.pageLayoutInner}>{props.children}</div>
    </section>
  );
}

export interface ApiBasedContentProps<T> {
  err?: ApiError;
  fetching?: boolean;
  placeholder?: () => JSX.Element;
  resp?: T;
  children?(resp: T): JSX.Element;
  customErrorMsg?: (err: ApiError) => string;
}
export function ApiBasedContent<T>(props: ApiBasedContentProps<T>) {
  if (props.err)
    return (
      <ApiErrorPanel customErromsg={props.customErrorMsg} apiError={props.err}>
        ERROR
      </ApiErrorPanel>
    );
  if (props.fetching) {
    if (props.placeholder) return props.placeholder();
    else return <LoadingSpinner />;
  }
  if (props.resp) return props.children?.(props.resp);
  console.error(`api based content without error not fethcing and no response`);
}

export interface PageTitleProps {
  className?: string;
}
export function PageTitle(props: PropsWithChildren<PageTitleProps>) {
  return <div className={classNames(styles.pageTitle, props.className)}>{props.children}</div>;
}

export interface PageDescProps {
  className?: string;
  kind?: 'default' | 'alert';
}
export function PageDesc(props: PropsWithChildren<PageDescProps>) {
  const kindCls = {
    [styles.descDefault]: props.kind === undefined || props.kind === 'default',
    [styles.descAlert]: props.kind === 'alert',
  };
  return <div className={classNames(styles.pageDesc, props.className, kindCls)}>{props.children}</div>;
}

export interface PageBodyProps {
  className?: string;
}
export function PageBody(props: PropsWithChildren<PageBodyProps>) {
  return <section className={classNames(styles.pageBody, props.className)}>{props.children}</section>;
}

export interface PageFooterProps {
  className?: string;
}
export function PageFooter(props: PropsWithChildren<PageBodyProps>) {
  return <section className={classNames(styles.pageFooter, props.className)}>{props.children}</section>;
}

export type FormGridWidth = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8';
export const formGrid = {
  w1: styles.formRowCol1,
  w2: styles.formRowCol2,
  w3: styles.formRowCol3,
  w4: styles.formRowCol4,
  w5: styles.formRowCol5,
  w6: styles.formRowCol6,
  w7: styles.formRowCol7,
  w8: styles.formRowCol8,
};
export interface FormRowProps {
  className?: string;
  noWrap?: boolean;
}
export function FormRow(props: PropsWithChildren<FormRowProps>) {
  return <div className={classNames(ifTrue(styles.noWrap, props.noWrap), styles.formRow, props.className)}>{props.children}</div>;
}
export function Spacer(props: { w: FormGridWidth }) {
  return <div className={classNames(styles.spacer, formGrid[`w${props.w}`])}></div>;
}
export function FormSeparator(props: { className?: string }) {
  return <hr className={classNames(styles.separator, props.className)} />;
}
export function FormGroupTitle(props: PropsWithChildren<{ className?: string }>) {
  return <h5 className={classNames(styles.formGroupTitle, props.className)}>{props.children}</h5>;
}

export interface LoadingSpinnerProps {
  className?: string;
  panelContentClassName?: string;
}
export function LoadingSpinner(props: PropsWithChildren<LoadingSpinnerProps>) {
  const { _t } = useBetterTranslate('page-layout');
  return (
    <div className={classNames(styles.spinner, props.className)}>
      <SpinnerIco />
      <div className={classNames(styles.panelContent, props.panelContentClassName)}>{props.children || <span className={styles.defaultLoadingMsg}>{_t(`loading`)}</span>}</div>
    </div>
  );
}

export function SuccessPanel(props: PropsWithChildren<{ className?: string; panelContentClassName?: string }>) {
  return (
    <div className={classNames(styles.successPanel, props.className)}>
      <div className={styles.ico}>
        <SuccessIco />
      </div>
      <div className={classNames(styles.panelContent, props.panelContentClassName)}>{props.children}</div>
    </div>
  );
}

export function ErrorPanel(props: PropsWithChildren<{ className?: string; panelContentClassName?: string; ico?: ReactNode }>) {
  return (
    <div className={classNames(styles.errorPanel, props.className)}>
      <div className={styles.ico}>{props.ico || <ErrorIco />}</div>
      <div className={classNames(styles.panelContent, props.panelContentClassName)}>{props.children}</div>
    </div>
  );
}

export function ApiErrorPanel(
  props: PropsWithChildren<{ className?: string; panelContentClassName?: string; apiError: ApiError; customErromsg?: (err: ApiError) => string | undefined }>
) {
  const { _t } = useBetterTranslate('page');
  if (props.apiError.statusCode === 403) {
    return (
      <ErrorPanel className={styles.apiError} ico={<UserLockIco />}>
        <div className={styles.code}>{`CODE 403`}</div>
        <div className={styles.txt}>{props.customErromsg?.(props.apiError) || _t('You dont have permissions to access this page')}</div>
      </ErrorPanel>
    );
  } else if (props.apiError.statusCode === 404) {
    return (
      <ErrorPanel className={styles.apiError} ico={<NotFoundIco />}>
        <div className={styles.code}>{`CODE 404`}</div>
        <div className={styles.txt}>{props.customErromsg?.(props.apiError) || _t('This page could not be found')}</div>
      </ErrorPanel>
    );
  } else {
    return (
      <ErrorPanel className={styles.apiError} ico={<NotFoundIco />}>
        <div className={styles.code}>CODE {props.apiError?.statusCode || 'UNBEKANNT'}</div>
        <div className={styles.txt}>{props.customErromsg?.(props.apiError) || _t('An unexpected error occoured')}</div>
      </ErrorPanel>
    );
  }
}
