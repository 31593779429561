import styles from './progress.module.scss';

import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import { ExtractFromUnion } from '../ts-type-utils';
import { Colors, coloToVar } from './utils';

export type Apperance = ExtractFromUnion<
  Colors,
  | 'green-kelly-green'
  | 'brown-gold-metallic'
  | 'functional-error-full'
  | 'primary-neutral-090'
  | 'secondary-teal'
  | 'blue-blue-jeans'
  | 'red-light-salmon'
  | 'functional-disabled-200'
  | 'dataviz-green-light-green'
  | 'dataviz-blue-pale-cyan'
  | 'dataviz-blue-medium-persian'
  | 'atenso-blue'
>;

export function ProgressBar(props: PropsWithChildren<{ className?: string; apperance: Apperance; innerBarClassName?: string; value: number }>) {
  const apperance = props.apperance || 'atenso-blue';
  return (
    <div style={{ minWidth: `${props.value}%` }} className={classNames(styles.progressContainer, props.className)}>
      <div style={{ width: `${props.value}%`, backgroundColor: coloToVar(apperance) }} className={classNames(styles.progress, styles[apperance], props.innerBarClassName)}>
        {props.children}
      </div>
    </div>
  );
}

export function ProgressBarStack(props: PropsWithChildren<{ className?: string }>) {
  return <div className={classNames(styles.stack, props.className)}>{props.children}</div>;
}
