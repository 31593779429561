import classNames from 'classnames';
import { ReactComponent as CloseIco } from '../../assets/close.svg';
import { ReactComponent as FilterIco } from '../../assets/filter.svg';
import useBetterTranslate from '../../utils/translation-utils';
import ButtonV2 from '../button';
import Box, { Divider } from '../utils';
import styles from './filter-toggle.module.scss';

export interface FilterToggleProps {
  className?: string;
  showClear?: boolean;
  toggleExpand?: () => void;
  onClear?: () => void;
}

export default function FilterToggle(props: FilterToggleProps) {
  const { _t } = useBetterTranslate('filter-toggle');

  return (
    <Box kind={'hflex'} align='center' gap='s' className={classNames(styles.root, props.className)}>
      {props.showClear && (
        <>
          <Box onClick={props.onClear} className={styles.clearBtn} kind={'hflex'} align='center' gap='xs'>
            <CloseIco />
            <Box>{_t('Clear all')}</Box>
          </Box>

          <Divider kind='v' />
        </>
      )}
      <ButtonV2 className={styles.btn} apperance='icon' onClick={props.toggleExpand}>
        <FilterIco />
      </ButtonV2>
    </Box>
  );
}
