import { translate } from '../../i18n';
import { ChargeBoxDomainStatus, ConnectorDomainStatus } from '../../services/api-client/csp-api';
import { Colors } from '../utils';

const _t = translate('common-translations');

export function rateTypeTranslation(rateType: string): string {
  if (rateType === 'HOME') return _t('Home');
  if (rateType === 'EMPLOYEE') return _t('Employee');
  if (rateType === 'PUBLIC') return _t('Public');
  if (rateType === 'WORK') return _t('Work');
  if (rateType === 'UNDEFINED') return _t('Unbekannt');

  return _t('Unbekannt');
}

export function rateTypeColor(rateType: string): Colors {
  if (rateType === 'HOME') return 'functional-disabled-200'; // todo, set correct
  if (rateType === 'EMPLOYEE') return 'green-kelly-green';
  if (rateType === 'PUBLIC') return 'dataviz-blue-medium-persian';
  if (rateType === 'WORK') return 'dataviz-green-light-green';
  if (rateType === 'UNDEFINED') return 'functional-disabled-200';

  return 'functional-disabled-200';
}

export const STATION_DOMAIN_STATUS_COLORS: Record<ChargeBoxDomainStatus, Colors> = {
  failure: 'functional-error-full',
  offline: 'brown-gold-metallic',
  notInOperation: 'primary-neutral-090',
  online: 'green-kelly-green',
};

export const CHARGEPOINT_DOMAIN_STATUS_COLORS: Record<ConnectorDomainStatus, Colors> = {
  available: 'secondary-teal',
  charging: 'blue-blue-jeans',
  occupied: 'red-light-salmon',
  failure: 'functional-error-full',
  notAvailable: 'primary-neutral-090',
  unknown: 'functional-disabled-200',
};
