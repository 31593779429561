import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import styles from './pill.module.scss';
import { Colors, coloToVar } from './utils';
// import CloseIcon from './pill.module.scss';

// export type Apperance = 'neutral' | 'success' | 'danger' | 'warn';
export type Apperance = Colors;
export default function Pill(props: PropsWithChildren<{ className?: string; onClick?: () => void; dataCy?: string; disabled?: boolean; tooltip?: string; apperance?: Apperance }>) {
  return (
    <button
      type='button'
      style={{
        backgroundColor: props.apperance ? coloToVar(props.apperance) : coloToVar('functional-disabled-200'),
      }}
      disabled={props.disabled}
      onClick={props.onClick}
      className={classNames(props.className, styles.root, props.onClick ? styles.clickable : '', props.children ? styles.hasChildren : '')}
      data-cy={props.dataCy}
      title={props.tooltip}
    >
      {props.children}
    </button>
  );
}
